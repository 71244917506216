
import React,{ Component } from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import DemoFooter from "components/Footers/DemoFooter.js";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import './style.css';

class VerbsNavbar extends Component{
  constructor(props){
    super(props);
    this.state = {
      navbarColor : '#ff8d30',
      navbarCollapse : false
    }
    this.toggleNavbarCollapse = this.toggleNavbarCollapse.bind(this)
  }
   toggleNavbarCollapse = () => {
    //  alert(1);
    this.setState({
      navbarCollapse : !this.state.navbarCollapse
    })
    document.documentElement.classList.toggle("nav-open");
  };

  render(){
    return (
      <Navbar className={classnames("top", this.state.navbarColor)} expand="lg" style={{
  backgroundColor: '#ff8d30',
  fontWeight: "bold",
}}>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              href=""
              target="_blank"
              title="Coded by Creative Tim"
            >
             <h1 style={{color: '#ffffff',}}>Select Language</h1>
            </NavbarBrand>
            <button
              aria-expanded={this.state.navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: this.state.navbarCollapse
              })}
              onClick={this.toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-center"
            navbar
            isOpen={this.state.navbarCollapse}
          >
            <Nav navbar>
              <NavItem>
                <NavLink></NavLink>
              </NavItem>
              <NavItem>
                <NavLink></NavLink>
              </NavItem>
              <NavItem>
                <NavLink></NavLink>
              </NavItem>
              <NavItem>
                <NavLink></NavLink>
              </NavItem>
              <NavItem>
                <NavLink></NavLink>
              </NavItem>
              <NavItem>
               <NavLink></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
      </Navbar>
      
    );
  }
}

export default VerbsNavbar;
