
import React,{Component} from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

class  Index extends Component {
  render(){
    return (
      <div>
        <IndexNavbar />
        <IndexHeader />
        <div className="main">
          <DemoFooter />
        </div>
      </div>
    );
  }
  
}

export default Index;
