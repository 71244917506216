
import React,{Component} from "react";
import './style.css';
import IndexNavbar from "components/Navbars/authNavbar";
import DemoFooter from "components/Footers/DemoFooter.js";
import { getAllVerbs,getSearchVerbs } from "../../Reduxstate/Admin/action/verb";
import { connect } from "react-redux";
import FadeLoader from 'react-spinners/FadeLoader'
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class findVerb extends Component {
    constructor(props){
        super(props);
        this.state = {
            Lang_name : this.props.match.params.name,
            VerbDatas : {}
        }
        this.onShowResult = this.onShowResult.bind(this);
    }
    componentDidMount =() => {
        // var langId = this.props.match.params.langId;
        let data = {
            lang :  this.props.match.params.langId
        }
        this.props.getAllVerbs(data);
    }
    onShowResult = (lang,english) => {
        this.props.history.push('/home/' + lang + "/" + english);
    }

    onChangeSearch = (e) => {
        e.preventDefault();
        let data = {
            lang :  this.props.match.params.langId,
            search_word : e.target.value
        }
        this.props.getSearchVerbs(data);
    }

    componentWillReceiveProps = (nextProps) => {
        console.log(nextProps);
        if(nextProps.varbData.status === "success"){
            this.setState({
                VerbDatas : nextProps.varbData.data
            })
        } 
        if( nextProps.searchData.status === "search success") {
            this.setState({
                VerbDatas : nextProps.searchData.data
            })
        }
    }
    render(){
        if (localStorage.getItem("username") == null) {
            window.location.href = "/login";
          }
        return (
        <div>
            <IndexNavbar />
            <div className="main container">
                <div className = "row top-background">
                    <div className = "target-language">
                        <h3>{this.state.Lang_name} Verbs</h3>
                    </div>
                    <div className = "center">
                        <input type = "text" name = "search" placeholder = "search" onChange = {this.onChangeSearch}/>
                    </div>
                </div>
                <div className = "content">
                    <div className = "word-content">
                        {
                            this.state.VerbDatas.length == undefined ? <FadeLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} /> : 
                            this.state.VerbDatas.map((item,i) => {
                                return (<div  key = {i}>
                                <h3 onClick = {()=>this.onShowResult(item.lang,item.english)}>{item.infinitiv_text}</h3>
                                <hr /></div>
                                )
                            })
                        }
                    </div><hr />
                </div>
                <DemoFooter />
            </div>
        </div>
        );
    }
  
}
const mapStateToProps = state => ({
    varbData : state.verb.allVerbData,
    searchData : state.verb.searchData
  });
  
  export default connect(
  mapStateToProps, {getAllVerbs,getSearchVerbs}
  )(findVerb);