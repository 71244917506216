import React from 'react';

import { BrowserRouter as Router, Route} from "react-router-dom";


import store from '../Reduxstate/Admin/store';
import {Provider} from 'react-redux';
import Home from '../views/Index.js';
import SignIn from '../views/Auth/SignIn';
import SignUp from '../views/Auth/SignUp';
import Landing from '../views/LearnVerb/Home';
import Select from '../views/LearnVerb/selectLanguage';
import FindVerb from '../views/LearnVerb/findVerb';


class WebRoute extends React.Component {
    constructor(props) {
        super();
        console.log(process.env.PUBLIC_URL);
    }

    render() {
        return (
            <Router>
                <Provider store={store} >
                    <Route exact path="/" render={props => <Home {...props}/>}/>
                    <Route exact path="/login" render={props => <SignIn {...props}/>}/>
                    <Route exact path="/register" render={props => <SignUp {...props}/>}/>
                    {/* <Route exact path="/landing" render={props => <Landing {...props}/>}/> */}
                    <Route exact path="/selectLanguage" render={props => <Select {...props}/>}/>
                    <Route exact path="/home/:lang?/:english?" render={props => <Landing {...props}/>}/>
                    <Route exact path="/findverb/:name?/:langId?" render={props => <FindVerb {...props}/>}/>
                    
                    {/* <Route exact path="/home" name="Home" render={props => <Home {...props}/>} />
                    <Route exact path="/detail/:id" name="detail" render={props => <Detail {...props}/>} />
                    <Route exact path="/reviews" name="detail" render={props => <Reviews {...props}/>} />
                    <Route exact path="/addreview" name="detail" render={props => <AddReviews {...props}/>} />
                    <Route exact path="/shipping/:id" name="shipping" render={props => <Shipping {...props}/>} />
                    <Route exact path="/order" name="detail" render={props => <Order {...props}/>} />
                    <Route exact path="/faq" name="faq" render={props => <Faq {...props}/>} /> */}

                </Provider>
            </Router> 
        );
    }
}

export default WebRoute;