
import React,{Component} from "react";
import './style.css';
import IndexNavbar from "components/Navbars/authNavbar";
import VerbsNavbar from "components/Navbars/VerbsNavbar";
import DemoFooter from "components/Footers/DemoFooter.js";
import Language from "./lang.json";
// import Engliah from "../../assets/images/flags/assets_image_flags_english.png"

class  selectLanguage extends Component {
    constructor(props){
        super(props);
        this.state = {
            language : Language
        }
        this.onSelectLang = this.onSelectLang.bind(this);
    }
    onSelectLang = (name, Id, ImagePath) => {
        localStorage.setItem("image_path",ImagePath);
        localStorage.setItem("first_lang",name);
        localStorage.setItem("first_langId",Id);
        this.props.history.push('/findverb/' + name + "/" + Id);
    }
    render(){
        if (localStorage.getItem("username") == null) {
            window.location.href = "/login";
          }
        return (
        <div style={{
    backgroundColor: '#ff8d30',
  }}>
            <VerbsNavbar />
            <div className="main container">
                <div className = "row select-language">
                {
                    this.state.language.Language.length == undefined ? <p>sorry</p> :
                    this.state.language.Language.map((item,i)=>{
                        return (
                                <div key = {i}  className = "col-md-2 col-sm-4 col-xs-6" onClick = {()=>this.onSelectLang(item.name,item.lang_id,item.image_path)}>
                                    <div>
                                        <img src =  {item.image_path} className = "img-responsive" alt = "flag"/>
                                    </div>
                                    <h3 style={{
                                color: '#ffffff',
                              }}>
                                        {item.name}
                                    </h3>
                                </div>
                        )
                    })
                }
                </div>
                
            </div>
        </div>
        );
    }

}

export default selectLanguage;
