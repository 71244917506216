import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import WebRoute from './routes/WebRoute';
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends React.Component {

    constructor(props) {
        super();
        this.state = {
            isAdminPath: false,
            loading: false
        }
    }

    render() {
      return (
        <BrowserRouter>
            <React.Suspense fallback={loading()}>             
                <WebRoute/>
            </React.Suspense>
        </BrowserRouter>
      );
    }
  }
  
  export default App;